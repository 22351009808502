import React from 'react';
import Slider from '../componets/slider/Slider';
import Whoweare from '../componets/whoweare/Whoweare';
// import CountDown from '../componets/countdown/CountDown';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Aboutinfo from '../componets/partials/aboutinf/Aboutinfo';
import MediaRelease from './publications/MediaReleaseInner';

export const Home = () => {
  // const deadline = 'Oct 08, 2022 21:00:00';

  return (
    <>
      <Slider />
      <Whoweare></Whoweare>
      <Aboutinfo />
      <MediaRelease/>
      {/* <CountDown deadline={deadline} /> */}
    </>
  );
};
