import React from 'react';
import './executive.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const GET_EXCUTIVE = gql`
  query {
    executives(pagination: { limit: 100 }) {
      data {
        attributes {
          executive_type
          user_info(pagination: { limit: 100 }) {
            names
            email
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const backgroundImg = {
  backgroundImage: 'url(./images/banner/banner.png)',
};
const Executive = () => {
  const executivesData = useQuery(GET_EXCUTIVE);
  const executives = executivesData?.data;

  return (
    <div className="container_executive">
      <div className="examinations-container">
        <Tabs
          defaultActiveKey="Executive"
          id="uncontrolled-tab-example"
          className="mb-3 justify-content-center"
        >
          {executives?.executives.data.map((executive, key) => (
            <Tab
              eventKey={executive?.attributes.executive_type}
              key={key}
              title={executive?.attributes.executive_type}
            >
              {executive?.attributes.executive_type === "Management" ? (
                <div className="featured-executives" style={backgroundImg}>
                  <div className="container px-md-0">
                    <div className="row">
                      {executive?.attributes.user_info.map((member, key) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 ">
                          <div className="bio-box">
                            <div className="profile-img">
                              <div className="dlab-border-left"></div>
                              <div className="dlab-border-right"></div>
                              <div className="dlab-media">
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    member?.image?.data?.attributes?.url
                                  }
                                  alt="Doctor"
                                  className="img-fluid img-center-sm img-center-xs"
                                />
                              </div>
                              <div className="overlay">
                                <div className="overlay-txt">
                                  <ul className="list-unstyled list-inline sm-links">
                                    <li className="list-inline-item">
                                      <a href="/">
                                        <i className="fab fa-facebook-f"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="/">
                                        <i className="fab fa-linkedin-in"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="/">
                                        <i className="fab fa-twitter"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <Link
                                        to={
                                          executive?.attributes
                                            .executive_type === "Council"
                                            ? "#"
                                            : `/executive/${member?.email}/${executive?.attributes.executive_type}`
                                        }
                                      >
                                        <i className="fas fa-user"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="txt-holder">
                              <h6>{member?.names}</h6>
                              <p className="designation">
                                {member?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ECoL_team">
                  <div className="container">
                    <div className="row mt-4 d-flex justify-content-center">
                      {executive?.attributes.user_info.map((member, key) => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={key}>
                          <div className="team_member_box">
                            <div className="team_member_pic">
                              <div className="item-image">
                                <Link
                                  className="item-frame"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      process.env.REACT_APP_API_URL +
                                      member?.image?.data?.attributes?.url +
                                      ")",
                                    backgroundColor: "lightgrey",
                                  }}
                                  to={
                                    executive?.attributes.executive_type ===
                                    "Council"
                                      ? "#"
                                      : `/executive/${member?.email}/${executive?.attributes.executive_type}`
                                  }
                                  rel="noreferrer"
                                >
                                  {" "}
                                </Link>
                              </div>
                            </div>
                            <div className="team_member_info">
                              <div className="info">
                                <h5>
                                  <strong>{member?.names}</strong>
                                </h5>
                                <p className="text-info">
                                  {member?.description}
                                </p>
                                <p> {member?.email} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Executive;
