import React from 'react';
import './whoweare.css';
function whoweare() {
  return (
    <>
      <section className="benifit">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 gy-2">
              <div className="sec-title style-two mb-tt">
                <h2 className="main-heading2">Portals</h2>
                <span className="decor">
                  <span className="inner" />
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 gy-2">
              <a href="https://ecol.org.ls/private-candidate">
                <div className="benifit__item_first">
                  <i className="fas fa-address-card"></i>
                  <div className="service_text">Online registration</div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 gy-2">
              <a href="https://ecol.org.ls/services">
                <div className="benifit__item_second">
                  <i class="fas fa-credit-card"></i>
                  <div className="service_text">e-Services</div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 gy-2">
              <a href="/my-result">
                <div className="benifit__item_last">
                  <i class="fas fa-search"></i>
                  <div className="service_text">My Result</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default whoweare;
