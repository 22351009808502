import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import ScrollToTop from './componets/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache(),
});

// http://www.examscouncil.org.ls:1337/api/opportunities?populate=opportunities.file.file

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
