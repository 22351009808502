import React from 'react';
import './faq.css';

const Faq = () => {
  const toggleFAQ = (event) => {
    event.preventDefault();
    event.currentTarget.classList.toggle('active');
    var panel = event.currentTarget.nextElementSibling;
    if (panel.style.display === 'block') {
      panel.style.display = 'none';
    } else {
      panel.style.display = 'block';
    }
  };

  return (
    <div>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Frequently Asked Questions</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">
              Frequently Asked Questions
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}

      <section className="faq">
        <div className="container">
          <div class="sec-title text-center">
            <h2 class="main-heading2">Frequently Asked Questions</h2>
            <span class="decor">
              <span class="inner"></span>
            </span>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-3">
              <ul>
                <li>
                  <a href="#faq1">Results</a>
                </li>
                <li>
                  <a href="#faq2">Remark</a>
                </li>
                <li>
                  <a href="#faq3">Certificates</a>
                </li>
                <li>
                  <a href="#faq4">Examination </a>
                </li>
              </ul>
            </div>
            <div className="col-md-12 col-lg-9">
              <div className="accordion-wrapper">
                <div className="faq-group" id="faq1">
                  <h3>RESULTS</h3>
                  <div className="accordion-item">
                    <a href="" onClick={toggleFAQ} className="accordion">
                      EVALUATION OF FOREIGN RESULTS
                    </a>
                    <div className="content">
                      <p>
                        Evaluation of foreign results (senior certificates of
                        other countries ONLY) is done at a charge of M425.00 per
                        evaluation. Most local institutions would request an
                        evaluation of foreign results for them to interpret them
                        correctly.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <a className="accordion" onClick={toggleFAQ}>
                      VERIFICATION OF RESULTS
                    </a>
                    <div className="content">
                      <p>
                        This service is available for universities, colleges,
                        employers to ensure that prospective students have
                        authentic and legitimate certificates.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="faq-group" id="faq2">
                  <h3>REMARKS</h3>
                  <div className="accordion-item">
                    <a onClick={toggleFAQ} className="accordion">
                      DATE OF APPLYING FOR REMARKS
                    </a>
                    <div className="content">
                      <p>
                        ECoL has numerous quality checks and controls in
                        ensuring that all candidates are marked fairly and
                        without any prejudice. However in the case a candidate
                        requires a remark of his / her results, applications for
                        remark for G7ELT, JC and LGCSE is open once the
                        respective results have been published. Application
                        period is one month after the publication of the results
                        in question for all the three examinations.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="faq-group" id="faq3">
                  <h3>CERTIFICATES</h3>
                  <div className="accordion-item" id="third">
                    <a onClick={toggleFAQ} className="accordion">
                      CHANGE OF NAMES ON THE CERTIFICATES
                    </a>
                    <div className="content">
                      <p>
                        ECoL does not change names on the certificate.
                        Certificates are the last document given to candidate as
                        a proof of his/ her performance. Names printed on
                        certificates are retrieved from individuals examinations
                        registration. This means that if during registration,
                        your names had been mis-spelt, then you will have to
                        ensure that you submit corrections to ECoL before
                        certificates could be printed. In case you have changed
                        your names, e.g. after marriage for ladies, your
                        marriage certificate will have to accompany your
                        certificates because ECoL will not change any names on
                        certificates{' '}
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <a onClick={toggleFAQ} className="accordion">
                      ISSUE ON LOST CERTIFICATES
                    </a>
                    <div className="content">
                      <p>
                        Candidates must safe keep all their important documents.
                        Certificates are issued once to the candidate. If there
                        is a need to issue out another proof of performance
                        document, a certifying statement will be issued out at a
                        cost that will be determined by ECoL. This document will
                        have similar or even more security feature to that of an
                        original certificate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="faq-group" id="faq4">
                  <h3>EXAMINATIONS </h3>
                  <div className="accordion-item">
                    <a onClick={toggleFAQ} className="accordion">
                      WHEN DO SUPPLEMENTARY EXAMINATIONS NORMALLY BEGIN?
                    </a>
                    <div className="content">
                      <p>
                        Supplementary examinations refer to May/ June
                        examinations. Normally in this session, examinations
                        start at around first week of May and end on the second
                        week of June.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item" id="#seventh">
                    <a onClick={toggleFAQ} className="accordion">
                      DEADLINE FOR JUNE SUPPLEMENTARY EXAMINATION’S REGISTRATION
                    </a>
                    <div className="content">
                      <p>
                        Registration for LGCSE supplementary is February end. No
                        late registration is accepted. London IGCSE deadline for
                        registration for May / June examinations is 20 March.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
