import  { React,useState } from "react";

const ReadMoreReadLess = ({ children }) => {
  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const toggleBtn = () => {
    setReadMoreShown((preState) => !preState);
  };

  return (
    <>
      {isReadMoreShown ? children : children.slice(0, 2)}
      <button className="mx-2 btn btn" onClick={toggleBtn}>
        {isReadMoreShown ? (
          <span>
            Read Less<i className="bx bxs-chevron-up"></i>
          </span>
        ) : (
          <span>
            Read More<i className="bx bxs-chevron-down"></i>
          </span>
        )}{" "}
      </button>
    </>
  );
};

export default ReadMoreReadLess;
