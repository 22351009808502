import React from 'react';
import ListItem from '../../componets/ListItem';
import './newsletter.css';

import { useQuery, gql } from '@apollo/client';

const GET_NEWSLETTERS = gql`
  query {
    newsletters {
      data {
        attributes {
          year
          newsletters {
            title
            newsletter {
              data {
                attributes {
                  url
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Newsletter() {
  const newsletterData = useQuery(GET_NEWSLETTERS);
  const newsletters = newsletterData?.data;
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>News Letter</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">News letter</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <div className="mt-3">
          {newsletters?.newsletters.data
            .slice()
            .sort((a, b) => b.attributes.year - a.attributes.year)
            .map((newsletter, key) => (
              <>
                <h3>{newsletter?.attributes?.year}</h3>
                {newsletter?.attributes?.newsletters.map((file, key) => (
                  <ListItem key={key}>
                    <span>
                      <i className="fas fa-file-pdf-o" aria-hidden="true"></i>
                      {file?.attributes?.title}
                    </span>
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL +
                        file?.newsletter?.data?.attributes?.url
                      }
                    >
                      {file?.title}
                    </a>
                  </ListItem>
                ))}
              </>
            ))}
        </div>
      </div>
    </>
  );
}
export default Newsletter;
