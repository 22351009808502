import React from 'react';
import '../../whoweare/colors.css';
import './aboutinfo.css';
import Accordion from 'react-bootstrap/Accordion';
function Aboutinfo() {
  return (
    <>
      <div className="section padding_layout_1 ">
        <div className="container">
          <div className="container px-md-0">
            <div className="sec-title text-center">
              <h2>Who is ECoL?</h2>

              <span className="decor">
                <span className="inner" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="full">
                <p align="justify">
                  The Examinations Council of Lesotho (ECoL) serves as the
                  authority responsible for developing, overseeing, and
                  maintaining a robust assessment system for Basic Education
                  that addresses Lesotho's educational requirements. ECoL
                  administers the Grade 7 End-of-Level Assessment, which gauges
                  learners' attainment of learning goals as they transition to
                  the Secondary Phase. Additionally, ECoL manages Grade 11
                  public examinations for the Lesotho General Certificate of
                  Secondary Education (LGCSE), a qualification endorsed by the
                  National University of Lesotho (NUL) for quality assurance.
                </p>
                <p>
                  <a className="btn" href="/about-us">
                    Read More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="full margin_bottom_2">
                <div className="accordion border_circle">
                  <div className="bs-example">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="4"
                            className="accordion-item"
                          >
                            <Accordion.Header>
                              <p className="panel-title">
                                <a
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseOne"
                                >
                                  <i className="fas fa-bullseye"></i> Mandate
                                </a>
                              </p>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="panel-body">
                                <p align="justify">
                                  Our mandate is to develop, regulate and
                                  maintain an effective assessment system for
                                  basic education that responds to the
                                  educational needs of learners in Lesotho
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <p className="panel-title">
                                <a
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseOne"
                                >
                                  <i className="fas fa-bullseye"></i> Mission
                                </a>
                              </p>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="panel-body">
                                <p align="justify">
                                  To develop a system of assessment at the basic
                                  education level that provides qualifications
                                  that address diverse educational needs of
                                  learners in the country, the region, and
                                  beyond.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <p className="panel-title">
                                <a
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseOne"
                                >
                                  <i className="fas fa-eye"></i> Vision
                                </a>
                              </p>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="panel-body">
                                <p align="justify">
                                  An advanced, progressive and credible school
                                  assessment system that provides qualifications
                                  of high quality in basic education that are
                                  recognized locally, regionally and
                                  internationally.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="3"
                            className="accordion-item"
                          >
                            <Accordion.Header>
                              <p className="panel-title">
                                <a
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseOne"
                                >
                                  <i className="fas fa-bullseye"></i> Values
                                </a>
                              </p>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="panel-body">
                                <p align="justify">
                                  To achieve the Vision and accomplish the
                                  Mission, the following values shall be upheld
                                  in all ECoL dealings with its clients and
                                  stakeholders
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Aboutinfo;
