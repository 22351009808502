import * as React from 'react';
import ECoLHQ from '../../images/ecol/ECoL_HQ.jpg';
import './profile.css';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

const Profile = () => {
  const { id, executive_type } = useParams();

  //   id/:executive_type
  const GET_USER = gql`
    query {
      executives(filters: { executive_type: { eq: "${executive_type}" } }) {
        data {
          attributes {
            executive_type
            user_info(
              filters: { email: { eq: "${id}" } }
            ) {
              names
              email
              description
              bio_description
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
              profile_image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const userData = useQuery(GET_USER);
  //   const user = userData?.data?.executives?.[0].data?.[0];
  const user = userData?.data?.executives?.data[0]?.attributes?.user_info[0];

  const colors = ['#B0C4DE'];
  var color = colors[Math.floor(Math.random() * colors.length)];
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(../../images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Profile</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Profile</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="profile-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <div className="sec-title style-two mb-tt">
                <h4>{user?.names}</h4>
                <p>{user?.description}</p>
                <span className="decor">
                  <span className="inner" />
                </span>
              </div>
              <p className="text-justify">
                <ReactMarkdown>{user?.bio_description}</ReactMarkdown>
              </p>
            </div>
            <div className="col-sm-12 col-md-5">
              <div
                className="image-container"
                style={{
                  background: `linear-gradient(45deg, #D4DFED,${color})`,
                }}
              >
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    user?.profile_image?.data?.attributes?.url
                  }
                  alt="Profile"
                />
              </div>
            </div>
          </div>
          <div>
            <a href={'/about-us'} className="btn">
              About us page
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
