import React from 'react';
import './pastquestionpapers.css';
import ListItem from '../../componets/ListItem';
import { useQuery, gql } from '@apollo/client';

const GET_QUESTION_PAPERS = gql`
  query {
    pastQuestionPapers(pagination: { limit: 100 }) {
      data {
        attributes {
          year
          question_paper {
            data {
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
  }
`;

function PastQuestionPaper() {
  const pastQuestionPapersData = useQuery(GET_QUESTION_PAPERS);
  const pastQuestionPapers = pastQuestionPapersData?.data;
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Past Question Papers</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Questions Paper</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <div className="sec-title style-two mb-tt">
          <h2 className="main-heading2">
            Past Question Papers {process.env.RECT_APP_API_URL}
          </h2>
          <span className="decor">
            <span className="inner"></span>
          </span>
        </div>
        <div className="mt-3">
          {pastQuestionPapers?.pastQuestionPapers.data.map(
            (pastQuestionPaper, key) => (
              <ListItem key={key}>
                <a
                  target="_blank"
                  href={
                    process.env.REACT_APP_API_URL +
                    pastQuestionPaper.attributes.question_paper.data.attributes
                      .url
                  }
                >
                  {
                    pastQuestionPaper.attributes.question_paper.data.attributes
                      .name
                  }
                </a>
              </ListItem>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default PastQuestionPaper;
