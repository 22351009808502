import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'fontawesome-4.7/css/font-awesome.min.css';
import './App.css';
import Header from './componets/partials/Header/Header';

import { Route, Routes } from 'react-router-dom';
import ScrollToTopButton from './componets/scroll/ScrollToTopButton';
// Comments

// Pages
import { Home } from './pages/Home';
import Services from './pages/Services';
import Faq from './pages/Faq';
import Timetables from './pages/examinations/Timetables';
import Fees from './pages/examinations/Fees';
import PastQuestionPaper from './pages/examinations/PastQuestionPaper';
import Programmes from './pages/examinations/Programmes';
import NationalCenters from './pages/examinations/NationalCenters';
import ExaminerReport from './pages/examinations/ExaminerReport';
import Syllabus from './pages/examinations/Syllabus';
import Certificate from './pages/examinations/Certificates';

import Opportunities from './pages/opportunities/Opportunities';
import Results from './pages/examinations/Result';
import Result from './pages/portals/Result';
import Registration from './pages/portals/Registration';

import MediaRelease from './pages/publications/MediaRelease';
import Documents from './pages/publications/Documents';
import Gallery from './pages/publications/Gallery';
import Newsletter from './pages/publications/Newsletter';

import AboutUs from './pages/aboutus/AboutUs';

import Executive from './pages/aboutus/Executive';
import ContactUs from './pages/aboutus/ContactUs';
import Footer from './componets/partials/Footer/Footer';
import Department from './pages/aboutus/Department';
import Profile from './pages/aboutus/Profile';

function App() {
  return (
    <div className="App">
      <div className="main">
        <Header />
        <div className="main-wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/media-release" element={<MediaRelease />} />
            <Route path="/timetables" element={<Timetables />} />
            <Route path="/examinations-fees" element={<Fees />} />
            <Route path="/results" element={<Results />} />
            <Route
              path="/past-question-paper"
              element={<PastQuestionPaper />}
            />
            <Route path="/programmes" element={<Programmes />} />
            <Route path="/syllabus" element={<Syllabus />} />
            <Route path="/examiners-report" element={<ExaminerReport />} />
            <Route path="/national-centers" element={<NationalCenters />} />
            <Route path="/certificates" element={<Certificate />} />
            <Route path="/my-result" element={<Result />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/newsletters" element={<Newsletter />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/opportunities" element={<Opportunities />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/executive" element={<Executive />} />
            <Route
              path="/executive/:id/:executive_type"
              element={<Profile />}
            />
            <Route path="/department/:slug" element={<Department />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <ScrollToTopButton />
      </div>
      <Footer />
    </div>
  );
}

export default App;
