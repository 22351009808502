import React, { useEffect, useRef } from 'react';
import './aboutus.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Accordion from 'react-bootstrap/Accordion';
import Executive from './Executive';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import ReadMoreReadLess from './../../componets/ReadMoreReadLess';

const GET_DEPARTMENTS = gql`
  query {
    departments(pagination: { limit: 100 }, sort: "id:asc") {
      data {
        attributes {
          slug
          department_name
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const backgroundImg = {
  backgroundImage: 'url(./images/banner/banner.png)',
};

const AboutUs = () => {
  const AboutUsRef = useRef(null);

  const departmentData = useQuery(GET_DEPARTMENTS);
  const departments = departmentData?.data;

  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      800: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    nav: false,
    dots: true,
  };
  return (
    <>
      <div className="contact">
        {/* <!-- inner page banner --> */}
        <div
          className="main-banner"
          style={{
            background: 'url(./images/banner/banner.png) center top',
          }}
        >
          <div className="container px-md-0">
            <h2>
              <span>About Us</span>
            </h2>
          </div>
        </div>
        <div className="breadcrumb">
          <div className="container px-md-0">
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <a href="/home">Home</a>
              </li>
              <li className="list-inline-item active">About Us</li>
            </ul>
          </div>
        </div>
        {/* <!-- inner page banner --> */}
        {/* <!-- About us --> */}
        <div className="section padding_layout_1 " id="whoisecol">
          <div className="container">
            <div className="container px-md-0">
              <div className="sec-title text-center">
                <h2>Who is ECoL?</h2>

                <span className="decor">
                  <span className="inner" />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="full">
                  <ReadMoreReadLess>
                    <p align="justify">
                      The Examinations Council of Lesotho (ECoL) serves as the
                      authority responsible for developing, overseeing, and
                      maintaining a robust assessment system for Basic Education
                      that addresses Lesotho's educational requirements. ECoL
                      administers the Grade 7 End-of-Level Assessment, which
                      gauges learners' attainment of learning goals as they
                      transition to the Secondary Phase. Additionally, ECoL
                      manages Grade 11 public examinations for the Lesotho
                      General Certificate of Secondary Education (LGCSE), a
                      qualification endorsed by the National University of
                      Lesotho (NUL) for quality assurance.
                    </p>

                    <p align="justify"></p>
                    <p align="justify">
                      ECoL is also entrusted with conducting the biennial
                      Lesotho National Assessment of Educational Progress
                      (LNAEP) Survey in Grades 4 and 6. This survey, conducted
                      on behalf of the Ministry of Education and Training
                      (MoET), monitors the progress of the country's education
                      system. Furthermore, ECoL offers services including
                      evaluating foreign results, printing replacement
                      certificates, issuing migration certificates, verifying
                      foreign results and certificates, as well as administering
                      English proficiency tests. These services are accessible
                      online for enhanced efficiency, effectiveness, and client
                      convenience.
                    </p>
                  </ReadMoreReadLess>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="full margin_bottom_2">
                  <div className="accordion border_circle">
                    <div className="bs-example">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item
                              eventKey="4"
                              className="accordion-item"
                            >
                              <Accordion.Header>
                                <p className="panel-title">
                                  <a
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseOne"
                                  >
                                    <i className="fas fa-bullseye"></i> Goals
                                  </a>
                                </p>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="panel-body">
                                  <ul>
                                    <li>
                                      Establishment of an efficient and
                                      effective assessment system that drives
                                      developments in the country’s basic
                                      education system.
                                    </li>
                                    <li>
                                      Enhanced basic education outcomes attained
                                      through awarding qualifications that meet
                                      international standards.
                                    </li>
                                    <li>
                                      Re-invigorated assessment services and
                                      products informed by school-based
                                      assessment research.
                                    </li>
                                    <li>
                                      Enhanced credibility of assessment
                                      processes and products through
                                      benchmarking them with, and soliciting
                                      their accreditation from international and
                                      sub-regional professional assessment
                                      institutions.
                                    </li>
                                    <li>
                                      Automated business processes for improving
                                      ECoL’s institutional efficiency and
                                      organizational effectiveness.
                                    </li>
                                    <li>
                                      Enhanced security of assessment processes
                                      and products through up-to-date
                                      technologies.
                                    </li>
                                  </ul>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                <p className="panel-title">
                                  <a
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseOne"
                                  >
                                    <i className="fas fa-bullseye"></i> Function
                                  </a>
                                </p>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="panel-body">
                                  <ul>
                                    <li>
                                      Develop Lesotho Advanced Secondary
                                      Qualification (LASQ).
                                    </li>
                                    <li>
                                      Develop and administer all assessment
                                      materials at basic education level,
                                      including: Examination papers, Continuous
                                      assessment packages, National assessment
                                      surveys, End-of-level achievement
                                      assessments.
                                    </li>
                                    <li>
                                      Quality-assure all internal assessment
                                      processes.
                                    </li>
                                    <li>
                                      Conduct training of service providers.
                                    </li>
                                    <li>
                                      Manage assessments within the basic
                                      education school curriculumConduct
                                      training of service providers.
                                    </li>
                                    <li>
                                      Award certificates of attainment in basic
                                      education.
                                    </li>
                                    <li>
                                      Conduct educational research (Learners
                                      performance, NAEP)Award certificates of
                                      attainment in basic education.
                                    </li>
                                    <li>
                                      Evaluate/ verify foreign certificates.
                                    </li>
                                  </ul>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Department start --> */}
      <div id="governance">
        <Executive />
      </div>
      <div className="departments_body" id="department" style={backgroundImg}>
        <div className="wrapper col-lg-4 col-md-6 col-sm-12">
          <div className="container px-md-0">
            <div className="sec-title text-center">
              <h2>Departments</h2>

              <span className="decor">
                <span className="inner" />
              </span>
            </div>
          </div>
          <ul>
            {departments && (
              <OwlCarousel
                loop
                margin={10}
                autoplay
                items={4}
                responsiveClass={true}
                {...options}
              >
                {departments?.departments.data.map((department, key) => (
                  <div key={key}>
                    <li className="card col-lg-4 col-md-6 col-sm-12">
                      <div className="img">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            department?.attributes?.image?.data?.attributes?.url
                          }
                          alt="ECoL HQ"
                        />
                      </div>
                      <h2>{department.attributes.department_name}</h2>
                      <Link
                        to={`/department/${department.attributes.slug}`}
                        className="btn btn"
                      >
                        View{' '}
                      </Link>
                    </li>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </ul>
        </div>
      </div>
      {/* Space creator */}
      <div className="spaceCreator"></div>
    </>
  );
};

export default AboutUs;
