import React from 'react';
import './department.css';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

const Department = () => {
  const { slug } = useParams();
  const GET_DEPARTMENT = gql`
query {
  departments(filters: { slug: { eq: "${slug}" } }) {
    data {
      attributes {
        slug
        department_name
        description
        image {
            data {
              attributes {
                url
              }
            }
          }
      }
    }
  }
}
`;

  const departmentData = useQuery(GET_DEPARTMENT);
  const department = departmentData?.data?.departments?.data?.[0];

  console.log(department);
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(../images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>{department?.attributes?.department_name}</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ol className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">
              {department?.attributes?.department_name}
            </li>
          </ol>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="department-container">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  department?.attributes?.image?.data?.attributes?.url
                }
                alt="ECoL HQ"
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="department-about">
                <div className="sec-title style-two mb-tt">
                  <h2 className="main-heading2">Functions</h2>
                  <span className="decor">
                    <span className="inner"></span>
                  </span>
                </div>
                <ReactMarkdown>
                  {department?.attributes?.description}
                </ReactMarkdown>
                <p>
                  <a className="btn" href="/about-us">
                    About us page
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Department;
