import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useQuery, gql } from '@apollo/client';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './mediarelease.css';

function MediaRelease() {
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    nav: false,
    dots: true,
  };











  const GET_LOCATIONS = gql`
    query {
      mediaReleases {
        data {
          attributes {
            title
            description
            media_release {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) {
    return <div>{process.env.REACT_APP_API_URL}</div>;
  }
  if (error) {
    return <div>{process.env.REACT_APP_API_URL}</div>;
  }
  return (
    <div className="main_media">
      <div className="container px-md-0 main-container">
        <div className="container px-md-0">
          <div className="sec-title text-center">
            <h2>Media Release</h2>
            <span className="decor">
              <span className="inner" />
            </span>
          </div>
        </div>
        <div className="notification-boxes row">
       

<OwlCarousel className="owl-theme" {...options}>
                {data.mediaReleases.data.map((mediaRelease, key) => (
            


              <div key={key}>
              <div className="box hover-border-outer hover-border">
                <div className="icon">
                  <i className="fas fa-bell" />
                </div>
                <h4>{mediaRelease.attributes.title}</h4>
                <p className="media_p">
                {
                      mediaRelease.attributes.description.substring(0, 120) +
                      "..."
                    }
                </p>
                <a
                  href={
                    process.env.REACT_APP_API_URL +
                    mediaRelease?.attributes?.media_release?.data?.attributes
                      ?.url
                  }
                  className="btn"
                >
                  Read More{' '}
                </a>
              </div>
            </div>
                ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
export default MediaRelease;
