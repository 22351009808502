import React from 'react';
import './documents.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListItem from '../../componets/ListItem';

const Documents = () => {
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Documents</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Documents</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <div className="mt-1">
          <Tabs
            defaultActiveKey="guidelines"
            id="uncontrolled-tab-example"
            className="mb-3 justify-content-center"
          >
            <Tab eventKey="guidelines" title="Guidelines">
              <ListItem>
                <a href="">Guidelines (PDF, 128KB)</a>
              </ListItem>
            </Tab>
            <Tab eventKey="reports" title="Reports">
              <h3>Anual Reports</h3>
              <ListItem>
                <a href="">Anual Reports (PDF, 128KB)</a>
              </ListItem>
              <h3>Research Reports</h3>
              <ListItem>
                <a href="">Research (PDF, 128KB)</a>
              </ListItem>
              <h3> Quality Assurance</h3>
              <ListItem>
                <a href=""> Quality Assurance (PDF, 128KB)</a>
              </ListItem>
            </Tab>
            <Tab eventKey="policies" title="Policies">
              <ListItem>
                <a href="">Policies</a>
              </ListItem>
            </Tab>
            <Tab eventKey="strategic-plan" title="Strategic Plan ">
              <h3> Strategic Plan </h3>
              <ListItem>
                <a href="">Strategic Plan 2021-2025</a>
              </ListItem>
              <h3>Anual Plan</h3>
              <ListItem>
                <a href="">Anual Plan (PDF, 128KB)</a>
              </ListItem>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Documents;
