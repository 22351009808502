import React from 'react';
import './footer.css';
import Logo from './../../../logo/logo.png';
import FloatingWhatsApp from 'react-floating-whatsapp';
import { Link } from 'react-router-dom';
import LogoSAP1005CA from '../../../images/ISO/SAP1005CA-01.png';
import LogoSAP1005IT from '../../../images/ISO/SAP1005IT-01.png';

const footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div className="footer-links">
              <h4>Head Quarters</h4>
              <div className="address ">
                <p>Examination Council of Lesotho: Government Office</p>
                <p>50 Constitution Rd </p>
                <p>P.O. Box 507 </p>
                <p>Maseru 100 </p>
                <p>Lesotho </p>
              </div>
              <div className="contact-info">
                <p>
                  <i className="fas fa-phone"></i>&nbsp;&nbsp;(+266) 22312880 /
                  5230 0100
                </p>
                <p className="email">
                  <i className="far fa-envelope">&nbsp;&nbsp;</i>
                  info@examscouncil.org.ls
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div className="footer-links">
              <h4>Khubetsoana Office</h4>
              <div className="address">
                <p>Examination Council of Lesotho: Board of Education</p>
                <p>A1 Foso Rd</p>
                <p>P.O. Box 507</p>
                <p>Berea 200</p>
                <p>Lesotho </p>
              </div>
              <div className="contact-info">
                <>
                  <i className="fas fa-phone"></i>&nbsp;&nbsp;(+266) 22312880 /
                  5230 0100
                </>
                <p className="email">
                  <i className="far fa-envelope">&nbsp;&nbsp;</i>
                  info@examscouncil.org.ls
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <div className="col useful-links">
                  <li>
                    <Link to="/my-result">Individual Results</Link>
                  </li>
                  <li>
                    <Link to="/certificates">Available Certificates</Link>
                  </li>
                  <li>
                    <Link to="/examinations-fees">Examination Fees</Link>
                  </li>
                  <li>
                    <Link to="/timetables">Time Tables</Link>
                  </li>
                </div>
                <div className="col useful-links">
                  <li>
                    <Link to="/registration">Online Registration</Link>
                  </li>
                  <li>
                    <Link to="/results">Results & Statistics</Link>
                  </li>
                  <li>
                    <Link to="/programmes">Programmes</Link>
                  </li>
                  <li>
                    <Link to="/syllabus">Syllabus</Link>
                  </li>
                  <li>
                    <Link to="/opportunities">Opportunities</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-2">
            <div className="footer-links">
              <h4>ISO Certified</h4>
              <div className="iso-logo">
                <p>ISO/IEC 27001:2013</p>
                {/* <img src={LogoSAP1005IT} alt="ISO" /> */}
                <p>ISO/IEC 9001:2015</p>
                {/* <img src={LogoSAP1005CA} alt="ISO" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="socials">
        <Link to="/contact-us" target="#map">
          <i className="fas fa-map-marker-alt"> </i>
        </Link>
        <a href="https://web.facebook.com/examslesotho/?_rdc=1&_rdr" target="/">
          <i className="fab fa-facebook" id="facebook"></i>
        </a>
        <a href="https://twitter.com/examslesotho" target="_/">
          <i className="fab fa-twitter" id="twitter" aria-hidden="true"></i>
        </a>
        <a href="https://instagram.com/examscouncil_lesotho" target="/">
          <i className="fab fa-instagram" id="instagram" aria-hidden="true"></i>
        </a>

        <a id="myButton">
          <FloatingWhatsApp
            styles={{
              marginTop: 0,
              position: 'absolute',
              top: -17 + 'px',
              left: -8 + 'px',
              width: 1.8 + 'em',

              backgroundColor: 'transparent',
            }}
            statusMessage="Examinations Council of Lesotho"
            avatar={Logo}
            chatMessage="Hello there! 🤝 \nHow can we help?"
            phoneNumber="0026622312880"
          />
        </a>
      </div>
      <hr></hr>
      <p className="copy">
        &copy; {new Date().getFullYear()} | Examinations Council of Lesotho. All
        rights reserved.
      </p>
    </footer>
  );
};

export default footer;
