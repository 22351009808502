import React from 'react';
import './examinerreport.css';
import Tab from 'react-bootstrap/Tab';
import ListItem from '../../componets/ListItem';
import Tabs from 'react-bootstrap/Tabs';

import { useQuery, gql } from '@apollo/client';

const GET_EXAMINER_REPORTS = gql`
  query {
    examinerReports(pagination: { limit: 100 }, sort: "year:ASC") {
      data {
        attributes {
          year
          examiner_report(pagination: { limit: 100 }) {
            data {
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
  }
`;

const ExaminerReport = () => {
  const reportsData = useQuery(GET_EXAMINER_REPORTS);
  const reports = reportsData?.data;

  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Examiner Report</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Examiner Report</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <Tabs
          defaultActiveKey="2023"
          id="uncontrolled-tab-example"
          className="mb-3 justify-content-center"
        >
          {reports?.examinerReports.data
            .slice()
            .sort((a, b) => b.attributes.year - a.attributes.year)
            .map((report, key) => (
              <Tab
                eventKey={report.attributes.year}
                title={report?.attributes?.year}
                key={key}
              >
                {report.attributes.examiner_report.data.map((file, key) => (
                  <ListItem key={key}>
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL + file?.attributes?.url
                      }
                    >
                      {file?.attributes?.name}
                    </a>
                  </ListItem>
                ))}
              </Tab>
            ))}
        </Tabs>
      </div>
    </>
  );
};

export default ExaminerReport;
