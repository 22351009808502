import React from 'react';
import './syllabus.css';
import ListItem from '../../componets/ListItem';

// syllabus

import { useQuery, gql } from '@apollo/client';

const year = new Date().getFullYear();

const GET_SYLLABI = gql`
  query {
    syllabi(pagination: { limit: 100 }) {
      data {
        attributes {
          level
          year
          subject_name
          syllabus {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function Syllabus() {
  const syllabusData = useQuery(GET_SYLLABI);
  const syllabi = syllabusData?.data;

  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Examinations</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Syllabus</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <div className="sec-title style-two mb-tt">
          <h2 className="main-heading2">LGCSE Syllabus 2021</h2>
          <span className="decor">
            <span className="inner"></span>
          </span>
        </div>
        <div className="mt-3">
          {syllabi?.syllabi.data.map((syllabus, key) => (
            <ListItem key={key}>
              <a
                target="_blank"
                href={
                  process.env.REACT_APP_API_URL +
                  syllabus.attributes.syllabus.data.attributes.url
                }
              >
                {syllabus.attributes.subject_name}
              </a>
            </ListItem>
          ))}
        </div>
      </div>
    </>
  );
}

export default Syllabus;
