import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListItem from '../../componets/ListItem';
import React from 'react';

import { useQuery, gql } from '@apollo/client';

const GET_CETIVICATES_LIST = gql`
  query {
    certificates(pagination: { limit: 100 }) {
      data {
        attributes {
          description
          released_date
          year
          certificates_list {
            data {
              attributes {
                url
                name
              }
            }
          }
        }
      }
    }
  }
`;

const Certificates = () => {
  const certificatesData = useQuery(GET_CETIVICATES_LIST);
  const certificates = certificatesData?.data;
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Certificates</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Certificates</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}

      <div className=" container px-md-0 main-container">
        <div class="sec-title style-two mb-tt">
          <h2 class="main-heading2">Available Certificates</h2>
          <span class="decor">
            <span class="inner"></span>
          </span>
        </div>
        <div className="mt-3">
          {certificates?.certificates.data.map((certificate, key) => (
            <ListItem key={key}>
              <a
                target="_blank"
                href={
                  process.env.REACT_APP_API_URL +
                  certificate.attributes.certificates_list.data.attributes.url
                }
              >
                {certificate.attributes.description}{' '}
                {certificate.attributes.released_date}
              </a>
            </ListItem>
          ))}
        </div>
      </div>
    </>
  );
};

export default Certificates;
