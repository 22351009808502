import React from 'react';
import './timetable.css';
// Import everything needed to use the `useQuery` hook
import { useQuery, gql } from '@apollo/client';

const GET_LOCATIONS = gql`
  query {
    timetables {
      data {
        attributes {
          level
          session
          year
          timetable {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function Timetables() {
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>loading...</div>;
  }

  // console.log(data.timetables.data);
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Timetables</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Timetables</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <div className="container px-md-0">
          <div className="sec-title text-center">
            <h2>Timetables</h2>
            <span className="decor">
              <span className="inner" />
            </span>
          </div>
        </div>
        <div className="timetables-item-container">
          {data.timetables.data.map((timetable, key) => (
            // console.log(timetable.attributes.timetable.data.attributes.url)
            <div key={key} className="timetables-item">
              <div className="bar"></div>

              <div className="item">
                <i className="fas fa-file-pdf-o" aria-hidden="true"></i>
              </div>
              <h3>{timetable.attributes.level}</h3>
              <p>
                {timetable.attributes.year} {timetable.attributes.session}
              </p>

              <a
                className="btn"
                href={
                  process.env.REACT_APP_API_URL +
                  timetable.attributes.timetable.data.attributes.url
                }
                target="_blank"
              >
                Download
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Timetables;
