import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './contactus.css';

function ContactUs() {
  const mapLocationsRef = useRef(null);

  return (
    <div className="contact_container">
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Contact Us</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Contact Us</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      {/* Contact us form */}
      <div id="content" className="site__content  contact_container">
        <div className="container   ">
          <article
            id="post-825"
            className="post-825 page type-page status-publish hentry"
          >
            <div className="page-inner-wrap">
              <div className="entry-content">
                <div
                  data-contact-type="wp-post"
                  data-contact-id={825}
                  className="elementor contact-825"
                >
                  <section
                    className="contact-section contact-top-section contact-element contact-element-be7023a contact-section-boxed contact-section-height-default contact-section-height-default"
                    data-id="be7023a"
                    data-element_type="section"
                  >
                    <div className="contact-container contact-column-gap-default">
                      <div
                        className="contact-column contact-col-66 contact-top-column contact-element contact-element-fd63416"
                        data-id="fd63416"
                        data-element_type="column"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="contact-widget-wrap contact-element-populated">
                          <div
                            className="contact-element contact-element-22d24c1 ECoL-title-align-left contact-widget contact-widget-section-title-addons"
                            data-id="22d24c1"
                            data-element_type="widget"
                            data-widget_type="section-title-addons.default"
                          >
                            <div className="sec-title style-two mb-tt">
                              <h2>Get in touch</h2>
                              <span className="decor">
                                <span className="inner" />
                              </span>
                            </div>
                          </div>
                          <div
                            className="contact-element contact-element-be6e39d contact-widget contact-widget-ECoL-contactform-addons"
                            data-id="be6e39d"
                            data-element_type="widget"
                            data-widget_type="ECoL-contactform-addons.default"
                          >
                            <div className="contact-widget-container">
                              <div className="ECoL-form-wrapper">
                                <div
                                  className="wpcf7 js"
                                  id="wpcf7-f7616-p825-o1"
                                  lang="en-US"
                                  dir="ltr"
                                >
                                  <form
                                    action="/contact/#wpcf7-f7616-p825-o1"
                                    method="post"
                                    className="wpcf7-form init"
                                    aria-label="Contact form"
                                    noValidate="novalidate"
                                    data-status="init"
                                  >
                                    <div className="row">
                                      <div className="col-md-6">
                                        <p>
                                          <span
                                            className="wpcf7-form-control-wrap"
                                            data-name="first-name"
                                          >
                                            <input
                                              size={40}
                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="First Name"
                                              defaultValue=""
                                              type="text"
                                              name="first-name"
                                            />
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-md-6">
                                        <p>
                                          <span
                                            className="wpcf7-form-control-wrap"
                                            data-name="last-name"
                                          >
                                            <input
                                              size={40}
                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Last Name"
                                              defaultValue=""
                                              type="text"
                                              name="last-name"
                                            />
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <p>
                                      <span
                                        className="wpcf7-form-control-wrap"
                                        data-name="your-email"
                                      >
                                        <input
                                          size={40}
                                          className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                                          aria-required="true"
                                          aria-invalid="false"
                                          placeholder="Your Email"
                                          defaultValue=""
                                          type="email"
                                          name="your-email"
                                        />
                                      </span>
                                    </p>
                                    <p>
                                      <span
                                        className="wpcf7-form-control-wrap"
                                        data-name="your-subject"
                                      >
                                        <input
                                          size={40}
                                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true"
                                          aria-invalid="false"
                                          placeholder="Your Subject"
                                          defaultValue=""
                                          type="text"
                                          name="your-subject"
                                        />
                                      </span>
                                    </p>
                                    <p>
                                      <span
                                        className="wpcf7-form-control-wrap"
                                        data-name="your-message"
                                      >
                                        <textarea
                                          cols={40}
                                          rows={10}
                                          className="wpcf7-form-control wpcf7-textarea"
                                          aria-invalid="false"
                                          placeholder="Your Message"
                                          name="your-message"
                                          defaultValue={''}
                                        />
                                      </span>
                                    </p>
                                    <p>
                                      <input
                                        className="btn"
                                        type="submit"
                                        defaultValue="Submit"
                                      />
                                      <span className="wpcf7-spinner" />
                                    </p>
                                    <div
                                      className="wpcf7-response-output"
                                      aria-hidden="true"
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="contact-column contact-col-33 contact-top-column contact-element contact-element-9222f00"
                        data-id="9222f00"
                        data-element_type="column"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="contact-widget-wrap contact-element-populated">
                          <div
                            className="contact-element contact-element-22d24c1 ECoL-title-align-left contact-widget contact-widget-section-title-addons"
                            data-id="22d24c1"
                            data-element_type="widget"
                            data-widget_type="section-title-addons.default"
                          >
                            <div className="sec-title style-two mb-tt">
                              <h2>Physical address</h2>
                              <span className="decor">
                                <span className="inner" />
                              </span>
                            </div>
                          </div>
                          <div
                            className="contact-element contact-element-8b8f54b contact-align-left contact-icon-list--layout-traditional contact-list-item-link-full_width contact-widget contact-widget-icon-list"
                            data-id="8b8f54b"
                            data-element_type="widget"
                            data-widget_type="icon-list.default"
                          >
                            <div className="contact-widget-container">
                              <ul className="contact-icon-list-items">
                                <li className="contact-icon-list-item">
                                  <span className="contact-icon-list-icon">
                                    <a href="https://www.google.com/maps/place/Examinations+Council+of+Lesotho/@-29.3126878,27.4772229,658m/data=!3m1!1e3!4m10!1m2!2m1!1sECOL+HEADQUARTER!3m6!1s0x1e8dcb148b2c53d5:0xff038794b670536f!8m2!3d-29.3126926!4d27.4818363!15sChBFQ09MIEhFQURRVUFSVEVSkgERZ292ZXJubWVudF9vZmZpY2XgAQA!16s%2Fg%2F1pzwj_4qc?entry=ttu">
                                      <i
                                        aria-hidden="true"
                                        className="fas fa-map-marker-alt"
                                      />{' '}
                                    </a>
                                  </span>
                                  <span className="contact-icon-list-text">
                                    <strong>Head Qaurters</strong>
                                    <br />
                                    50 Constitution Road P.O. Box 507 Maseru 100
                                    Lesotho
                                  </span>
                                </li>
                                <li className="contact-icon-list-item">
                                  <span className="contact-icon-list-icon">
                                    <a href="https://www.google.com/maps/place/Exams+Council+of+Lesotho,+Khubetsoana/@-29.2695829,27.5387315,659m/data=!3m1!1e3!4m10!1m2!2m1!1sECOL+HEADQUARTER!3m6!1s0x1e8dcba7360ced03:0x88c0bd4543db40ea!8m2!3d-29.2695829!4d27.5432376!15sChBFQ09MIEhFQURRVUFSVEVSkgESYm9hcmRfb2ZfZWR1Y2F0aW9u4AEA!16s%2Fg%2F11gtzny0cf?entry=ttu">
                                      <i
                                        aria-hidden="true"
                                        className="fas fa-map-marker-alt"
                                      />{' '}
                                    </a>
                                  </span>
                                  <span className="contact-icon-list-text">
                                    <strong>Khubetsoana Office</strong>
                                    <br />
                                    A1 Foso Road P.O. Box 507 Berea 200 Lesotho
                                  </span>
                                </li>
                                <li className="contact-icon-list-item">
                                  <span className="contact-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      className="far fa-envelope"
                                    />{' '}
                                  </span>
                                  <span className="contact-icon-list-text">
                                    info@examscouncil.org.ls
                                  </span>
                                </li>
                                <li className="contact-icon-list-item">
                                  <span className="contact-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      className="fas fa-phone"
                                    />{' '}
                                  </span>
                                  <span className="contact-icon-list-text">
                                    {' '}
                                    (+266) 22312880 / 5230 0100
                                  </span>
                                </li>
                                <li className="contact-icon-list-item">
                                  <span className="contact-icon-list-icon">
                                    <i
                                      aria-hidden="true"
                                      className="fas fa-globe"
                                    />{' '}
                                  </span>
                                  <span className="contact-icon-list-text">
                                    www.examscouncil.org.ls
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <section
                            className="contact-section contact-inner-section contact-element contact-element-c290742 contact-section-boxed contact-section-height-default contact-section-height-default"
                            data-id="c290742"
                            data-element_type="section"
                          >
                            <div className="contact-container contact-column-gap-default">
                              <div
                                className="contact-column contact-col-100 contact-inner-column contact-element contact-element-74d182a"
                                data-id="74d182a"
                                data-element_type="column"
                              >
                                <div className="contact-widget-wrap contact-element-populated">
                                  <div
                                    className="contact-element contact-element-cf84311 contact-widget contact-widget-google_maps"
                                    data-id="cf84311"
                                    data-element_type="widget"
                                    data-widget_type="google_maps.default"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/* .entry-content */}
            </div>
          </article>
          {/* #post-## */}
        </div>
      </div>
      {/* Contact us form */}
      {/* Map */}
      <div className="container px-md-0">
        <div className="sec-title text-center">
          <h2>Directions</h2>

          <span className="decor">
            <span className="inner" />
          </span>
        </div>
      </div>
      <div class="map" ref={mapLocationsRef} id="mapLocations">
        <iframe
          width="100%"
          height="450"
          id="gmap_canvas"
          src="https://www.google.com/maps/ms?msa=0&amp;msid=201705158470841100411.0004ca32a33b0c06628b7&amp;ie=UTF8&amp;t=h&amp;ll=-29.289992,27.513885&amp;spn=0.119772,0.21904&amp;z=11&amp;output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
      {/* Map */}
    </div>
  );
}

export default ContactUs;
