import React from 'react';
import { Fancybox } from '@fancyapps/ui';
import './gallery.css';
import { useQuery, gql } from '@apollo/client';

const GET_GALLERIES = gql`
  query {
    galleries {
      data {
        attributes {
          description
          gallery {
            data {
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
  }
`;

function Gallery() {
  const galleriesData = useQuery(GET_GALLERIES);
  const galleries = galleriesData?.data;
  // Initialise Carousel
  return (
    <div>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: "url(./images/banner/banner.png) center top",
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Gallery</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Gallery</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <section className="gallery">
        <div className="container">
          <div className="row gallery_grid_row">
            {galleries?.galleries.data.map((gallery, group) => (
              <>
                <div className="col-lg-3 col-md-6  col-xs-12">
                  <div className="single_box">
                    <div className="img_footer">
                      <h3 className="img_caption">
                        {gallery?.attributes?.description}
                      </h3>
                      <p className="icon-links">
                        <a href="/">
                          <span className="fas fa-eye"></span>
                        </a>
                      </p>
                    </div>
                    <p className="imglist">
                      {gallery.attributes.gallery.data.map((file, index) => (
                        <>
                          <a
                            key={index}
                            href={
                              process.env.REACT_APP_API_URL +
                              file?.attributes?.url
                            }
                            data-fancybox={group}
                          >
                            <img
                              src={
                                process.env.REACT_APP_API_URL +
                                file?.attributes?.url
                              }
                            />
                          </a>
                        </>
                      ))}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gallery;
