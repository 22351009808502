import React from 'react';
import './result.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useQuery, gql } from '@apollo/client';

const GET_RESULT_LGCSE = gql`
  query {
    resultStatistics(
      filters: { level: { eq: "LGCSE" } }
      pagination: { limit: 100 }
    ) {
      data {
        attributes {
          level
          year
          top_achievers {
            data {
              attributes {
                url
              }
            }
          }
          school_performance {
            data {
              attributes {
                url
              }
            }
          }
          district_performance {
            data {
              attributes {
                url
              }
            }
          }
          subject_performance {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
const GET_RESULT_JC = gql`
  query {
    resultStatistics(filters: { level: { eq: "JC" } }) {
      data {
        attributes {
          level
          year
          top_achievers {
            data {
              attributes {
                url
              }
            }
          }
          school_performance {
            data {
              attributes {
                url
              }
            }
          }
          results {
            data {
              attributes {
                url
              }
            }
          }
          subject_performance {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
const Result = () => {
  const lgcseResultData = useQuery(GET_RESULT_LGCSE);
  const lgcseResult = lgcseResultData?.data;

  const jcResultData = useQuery(GET_RESULT_JC);
  const jcResult = jcResultData?.data;
  console.log(lgcseResultData?.data);
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Results & Statistics</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Results</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <Tabs
          defaultActiveKey="LGCSE"
          id="uncontrolled-tab-example"
          className="mb-3 justify-content-center"
        >
          <Tab eventKey="LGCSE" title="LGCSE">
            <div className="card mb-4 shadow-sm">
              <div className="card-header" id="LGCSE">
                <div className="sec-title style-two mb-tt">
                  <h2 className="main-heading2">LGCSE Results & Statistics</h2>
                  <span className="decor">
                    <span className="inner"></span>
                  </span>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <strong></strong>
                    <tr className="headertable">
                      <th>Year</th>
                      <th>National Top Achievers</th>
                      <th>School Performance</th>
                      <th>District Performance</th>
                      <th>Subject Performance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lgcseResult?.resultStatistics.data
                      .slice()
                      .sort((a, b) => b.attributes.year - a.attributes.year)
                      .map((result, key) => (
                        <tr key={key}>
                          <td className="year">{result?.attributes?.year}</td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.top_achievers?.data
                                  ?.attributes?.url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.school_performance?.data
                                  ?.attributes?.url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.district_performance?.data
                                  ?.attributes.url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.subject_performance?.data
                                  ?.attributes?.url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab>
          <Tab eventKey="JC" title="JC">
            <div className="card mb-4 shadow-sm">
              <div className="card-header" id="LGCSE">
                <h4 className="my-0 font-weight-normal">
                  JC Results & Statistics
                </h4>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr className="headertable">
                      <th>Year</th>
                      <th>National Top Ten</th>
                      <th>School Performance</th>
                      <th>Results</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jcResult?.resultStatistics.data
                      .slice() //This is the part
                      .sort((a, b) => b.attributes.year - a.attributes.year) //ordering by ascending
                      .map((result, key) => (
                        <tr key={key}>
                          <td className="year">2022</td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.top_achievers?.data
                                  ?.attributes?.url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.school_performance?.data
                                  ?.attributes?.url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                result?.attributes?.results?.data?.attributes
                                  .url
                              }
                              target="_blank"
                            >
                              Download{' '}
                              <i
                                className="fas fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Result;
