import React, { useState } from 'react';
import './notice.css';
import { useQuery, gql } from '@apollo/client';
import Marquee from 'react-fast-marquee';
import parse from "html-react-parser";

const GET_LOCATIONS = gql`
  query {
    announcement {
      data {
        attributes {
          announcement
        }
      }
    }
  }
`;
{
}
function getDate() {
  const today = new Date();
  const month = today
    .toLocaleString('default', { month: 'short' })
    .toUpperCase();
  const year = today.getFullYear();
  const date = today.getDate();
  const weekday = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'][
    new Date().getDay()
  ].toUpperCase();
  return `${weekday} -${date} ${month} ${year}`;
}
function Notice() {
  const [currentDate, setCurrentDate] = useState(getDate());
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  const announcement = data?.announcement?.data?.attributes?.announcement;

  console.log(data?.announcement?.data?.attributes?.announcement);

  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>loading...</div>;
  }
  return (
    <div className="latest--news">
      <div className="container">
        <div className="d-lg-flex">
          <div className="latest-title d-flex align-items-center text-nowrap text-white text-uppercase">
            Latest News
          </div>
          <div
            className="news-updates-list overflow-hidden mt-2 spacer"
            data-marquee="true"
            spacer
          >
            <Marquee>
              <ul className="nav ">
                <li>
                  <a className="text-white align-items-center" href="/">
                    {parse(announcement)}
                  </a>
                </li>
                <li>
                  <a className="text-white align-items-center" href="/">
                    {' '}
                    <span className="date-text"></span>
                  </a>
                </li>
              </ul>
            </Marquee>
          </div>

          <div className="current-date text-nowrap text-white  align-items-center">
            <span className="date-now  align-items-center">
              <i className="">TODAY:</i> {currentDate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notice;
