import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ListItem from '../../componets/ListItem';
import './opportunities.css';
import { useQuery, gql } from '@apollo/client';

const GET_OPPORTUNITIES = gql`
  query {
    opportunities(pagination: { limit: 100 }) {
      data {
        attributes {
          opportunity_type
          opportunities(pagination: { limit: 100 }, sort: "year:desc") {
            year
            file {
              description
              closing_date
              file {
                data {
                  attributes {
                    name
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Opportunities = () => {
  const opportunitiesData = useQuery(GET_OPPORTUNITIES);
  const opportunities = opportunitiesData?.data;

  const year = new Date().getFullYear();
  console.log(opportunities);

  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Opportunities</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Opportunities</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="examinations-container">
        <Tabs
          defaultActiveKey="vacancies"
          id="uncontrolled-tab-example"
          className="mb-3 justify-content-center"
        >
          {opportunities?.opportunities?.data?.map((opportunity, key) => (
            <Tab
              eventKey={opportunity?.attributes?.opportunity_type.toLowerCase()}
              title={opportunity?.attributes?.opportunity_type}
              key={key}
            >
              {opportunity?.attributes?.opportunities
                // .slice()
                // .sort((a, b) => b.attributes.year - a.attributes.year)
                .map((file, fileskey) => (
                  <div key={fileskey}>
                    <h3>
                      {' '}
                      {file.year === year
                        ? `${opportunity?.attributes?.opportunity_type} ${file.year}`
                        : `${file.year}`}{' '}
                    </h3>
                    {file?.file.map((name, kay) => (
                      <>
                        <ListItem key={kay}>
                          <a
                            target="_blank"
                            href={
                              process.env.REACT_APP_API_URL +
                              name?.file?.data?.attributes?.url
                            }
                          >
                            {name.description}{' '}
                            <i>Closing Date {name?.closing_date}</i>
                          </a>
                        </ListItem>
                      </>
                    ))}
                  </div>
                ))}
            </Tab>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default Opportunities;
