import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import './slider.css';
import { useQuery, gql } from '@apollo/client';

const GET_SLIDES = gql`
  query {
    slide {
      data {
        attributes {
          slides {
            title
            description
            button_url
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
function Slider() {
  const { data } = useQuery(GET_SLIDES);
  const slides = data?.slide?.data?.attributes?.slides;

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="main-slider">
      <div className="container-fluid">
        {slides && (
          <Carousel
            items={1}
            className="owl-theme"
            loop
            nav={true}
            autoplay={true}
            slidesToShow="1"
            slidesToScroll="1"
            infinite="true"
            arrows={true}
          >
            {slides?.map((slide, index) => (
              <Carousel.Item key={index}>
                <div className="slider-wrapper">
                  <div
                    className="image"
                    style={{
                      backgroundImage:
                        'url(' +
                        process.env.REACT_APP_API_URL +
                        slide?.image?.data?.attributes?.url +
                        ')',
                      height: '400px',
                      width: '100%',
                    }}
                  ></div>
                  <div className="slider-caption c-left">
                    <div className="container">
                      <div className="wrap-caption">
                        <h2>{slide?.title}</h2>
                        <div className="text center">{slide?.description}</div>
                        <div className="link-btn">
                          <a href={'/services'} className="btn">
                            View Services{' '}
                          </a>
                          <a
                            href={'/' + slide?.button_url}
                            className="btn btn1"
                          >
                            Learn More{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="slide-overlay"></div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}
export default Slider;
