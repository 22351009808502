import React from 'react';
import Pdf from '../../images/pdf.png';
import './registration.css';

function Registration() {
  return (
    <>
      {' '}
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Online Registration</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Online Registration</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <section className="registration_download">
        <div className="container registration-summary mt-2">
          <div className="row">
            <div className="col-12">
              <div class="sec-title style-two mb-tt">
                <h2 class="main-heading2">Online Registration</h2>
                <span class="decor">
                  <span class="inner"></span>
                </span>
              </div>

              <p className="registration-summary-brief">
                Online Registration is a web portal through which Private
                Candidates and School Candidates can register for ECoL
                administered examinations. The portal is categorised into two
                sections with each section dedicated to each type of candidate,
                that is, Private Candidates have a section used by only them in
                the web portal and school candidates have their own. The School
                candidates section in the web portal is used by schools to
                register candidates for examinations. While the Private
                Candidates section in the web portal is used by Private
                Candidates to register for examinations. Private Candidates are
                candidates who do not attend any formal schools administered
                School Candidates are candidates who attend formal schools. The
                documents below provide a guide on how to access each section of
                the web portal.
              </p>
            </div>
          </div>
        </div>
        <div className="container guide-row">
          <div className="row mb-2">
            <div className="col-xs-4 offset-10">
              <a
                href="https://ecol.org.ls/"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Register now
              </a>
            </div>
          </div>
        </div>
        <div className="container guide-row mt-4">
          <div className="row mb-4">
            <div className="col-md-6 guide">
              <div className="img-container">
                <img
                  src={Pdf}
                  alt="pdf placeholder for registration guide document"
                />
              </div>
              <div className="guide-container">
                <div className="guide-link">
                  <a
                    href="download/registration/PrivateRegistrationGuide.pdf"
                    target="_blank"
                  >
                    Private Candidates' Registration Guide
                  </a>
                </div>
                <div className="guide-info">
                  <p>Click above link to download</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <p className="guide-desc">
                This document serves as a guide for Private Candidates on how to
                access and use the Private Candidates section of the Online
                Registration web portal.
              </p>
            </div>
          </div>
        </div>

        <div className="container guide-row">
          <div className="row mb-4 mt-4">
            <div className="col-md-6 guide">
              <div className="img-container">
                <img
                  src={Pdf}
                  alt="pdf placeholder for registration guide document"
                />
              </div>
              <div className="guide-container">
                <div className="guide-link">
                  <a
                    href="download/registration/SchoolCandidatesRegistrationGuide.pdf"
                    target="_blank"
                  >
                    School Candidates' Registration Guide
                  </a>
                </div>
                <div className="guide-info">
                  <p>Click above link to download</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <p className="guide-desc">
                The document serves as a guide for Principals or School staff
                tasked with the administration of examinations registration, on
                how to use the School Candidates section of the registration
                portal to register their students for examinations.
              </p>
            </div>
          </div>
        </div>

        <div className="container guide-row">
          <div className="row mb-2 mt-4">
            <div className="col-md-6 guide">
              <div className="img-container">
                <img
                  src={Pdf}
                  alt="pdf placeholder for registration guide document"
                />
              </div>
              <div className="guide-container">
                <div className="guide-link">
                  <a href="download/registration/FAQ.pdf" target="_blank">
                    Online Registration FAQ's
                  </a>
                </div>
                <div className="guide-info">
                  <p>Click above link to download</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <p className="guide-desc">
                Have a question regarding the registration portal? This document
                contains a list of Frequently Asked Questions(FAQ's) and their
                answers. Still unanswered? Please do not hesitate to contact
                ECoL on the contact information provided on the website.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Registration;
